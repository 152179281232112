
$businesscopymachines-footer-background-color: #2f6177;
$businesscopymachines-benefits-h3-color: #567ed4;
$businesscopymachines-benefits-p-color: #5b5b5b;
$businesscopymachines-mainBanner-labelSelcted-color: linear-gradient(180deg, #FFA450 0%, #FF7A00 100%);;
$businesscopymachines-mainBanner-labelUnSelcted-color: #FFBA7A;
$businesscopymachines-mainBanner-img-color: #2532b1;
$businesscopymachines-mainBanner-primry-color: #ff9800;

:export {
   
    gorseYellowOrange: $businesscopymachines-mainBanner-primry-color;
    footerColor:$businesscopymachines-footer-background-color;
}
