
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";


.list-item {
    h3 {
        color: $businesscopymachines-benefits-h3-color;
    }
    p {
        color: $businesscopymachines-benefits-p-color;
    }
}

.heading {
    color: black;
    h2 {
        width: 100%;

        font-size: 25px;
        font-weight: bold;
    }
}
.img {
    svg {
        width: 100%;
        height: 100%;
    }
}
